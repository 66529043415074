import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Parameter } from '@shared/models/parameter';
import { Observable } from 'rxjs';
import { ErrorMessage } from '@shared/models/error.message';

@Injectable()
export class ErrorsStoreService {
    constructor(private store: Store) {}

    getAll(): Observable<ErrorMessage[]> {
        return this.store.select(state => state.errors.errors);
    }

}