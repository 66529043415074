import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiService } from '@services/api.service';
import { Category } from '@shared/models/category';

const RESOURCE_PATH = '/category';

@Injectable()
export class CategoryService {

    constructor(private apiService: ApiService,
        private store: Store) {
    }

    findAll() {
        return this.apiService.get(RESOURCE_PATH);
    }

    public create(data: Category) {
        return this.apiService.post(RESOURCE_PATH, data);
    }
}
