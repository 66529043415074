import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ProductionService } from '@services/production.service';
import { ErrorMessage } from '@shared/models/error.message';
import { Production } from '@shared/models/production';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { MessageService } from 'primeng/api';
import { Product } from '@shared/models/product';
import { ProductService } from '@services/product.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  providers: [MessageService]
})
export class HomepageComponent implements OnInit {
  productions: Production[];
  products: Product[];
  errors: ErrorMessage[] = [];

  form: FormGroup;

  constructor(private productionService: ProductionService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {
    this.store.dispatch([new LoadErrors()]);

    this.form = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.productionService.getAll(9, "creationDate").subscribe(data => {
      this.productions = data;
    });

    this.productService.getAll(null, 9, "creationDate").subscribe(data => {
      this.products = data;
    });
  }

}
