import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Product } from '@shared/models/product';
import { ApiService } from '@services/api.service';

const RESOURCE_PATH = '/products';

@Injectable()
export class ProductService {
  private _refresh = new Subject<void>();

  get refresh() {
    return this._refresh;
  }

  constructor(private apiService: ApiService) {
  }

  get(id: string | number) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + id);
  }

  getAll(providerIdFilter = null, limit: number = null, orderBy: string = null) {
    if (providerIdFilter) {
      return this.apiService.get(RESOURCE_PATH + "?provider=" + providerIdFilter);
    } else {
      var params = "";
      var sep = "?";
      if (limit) {
        params += sep + "limit=" + limit;
        sep = "&";
      }
      if (orderBy) {
        params += sep + "orderBy=" + orderBy;
        sep = "&";
      }

      return this.apiService.get(RESOURCE_PATH + (params ? params : ''));
    }
  }

  getAllInError() {
    return this.apiService.get(RESOURCE_PATH + '/error');
  }

  getAllProviders(productId) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + productId + '/providers');
  }

  getProviders(productId, providerId) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + productId + '/providers/' + providerId);
  }

  saveOrUpdateProduct(product: Product) {
    if (product.id) {
      return this.apiService
        .put(RESOURCE_PATH + '/' + product.id, product).pipe(
          tap(() => {
            this.refresh.next();
          })
        );
    } else {
      return this.apiService
        .post(RESOURCE_PATH, product);
    }
  }

  remove(id: string) {
    return this.apiService.delete(RESOURCE_PATH + '/' + id);
  }
}
