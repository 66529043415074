import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ParameterService } from '@services/parameter.service';
import { Parameter } from '@shared/models/parameter';
import { LoadParameters } from './parameters.actions';
import { map } from 'rxjs/operators';

export interface ParametersStateModel {
    parameters: Parameter[];
}

@State<ParametersStateModel>({
    name: 'parameters',
    defaults: {
        parameters: []
    }
})
@Injectable()
export class ParametersState {
    constructor(private parameterService: ParameterService) {
    }

    @Selector()
    static getParameters(state: ParametersStateModel) {
        return state.parameters;
    }

    @Action(LoadParameters)
    load(ctx: StateContext<ParametersStateModel>) {
        return this.parameterService.findAll().pipe(map(parameters => {
            ctx.setState({
                parameters: parameters
            });
        }));
    }

    @Selector()
    static parameterByName(state: ParametersStateModel) {
        return (type: string) => {
            return state.parameters.find(x => x.name === type);
        };
    }

}