import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ProviderIndexComponent } from './provider-index/provider-index.component';
import { ProviderRoutingModule } from './provider-routing.module';
import { ProviderViewComponent } from './provider-view/provider-view.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { SharedModule } from '@shared/shared.module';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule,
    TableModule,
    DialogModule,
    ToastModule,
    ProviderRoutingModule,
    TabViewModule,
    ConfirmDialogModule,
    ProgressBarModule,
    SharedModule,
    DataViewModule,
    CardModule,
    DropdownModule,
    InputSwitchModule,
  ],
  declarations: [
    ProviderIndexComponent,
    ProviderViewComponent,
  ]
})
export class ProviderModule {
}
