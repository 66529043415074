import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { RecipeIndexComponent } from './recipe-index/recipe-index.component';
import { RecipeRoutingModule } from './recipe-routing.module';
import { RecipeViewLineComponent } from './recipe-view-line/recipe-view-line.component';
import { RecipeViewComponent } from './recipe-view/recipe-view.component';
import { ProductionModule } from '../production/production-module';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule,
    TableModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    SplitButtonModule,
    CardModule,
    TabViewModule,
    ConfirmDialogModule,
    CalendarModule,
    ProgressBarModule,

    SharedModule,
    RecipeRoutingModule,
    MessagesModule,

    ProductionModule,
  ],
  declarations: [
    RecipeIndexComponent,
    RecipeViewComponent,
    RecipeViewLineComponent,
  ]
})
export class RecipeModule {
}
