import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Select } from '@ngxs/store';
import { ParameterService } from '@services/parameter.service';
import { Parameter } from '@shared/models/parameter';
import { map } from 'rxjs/operators';
import { ErrorMessage } from '@shared/models/error.message';
import { LoadErrors } from './errors.actions';
import { ProductService } from '@services/product.service';
import { RecipeService } from '@services/recipe.service';
import { forkJoin, Observable } from 'rxjs';

export interface ErrorsStateModel {
    errors: ErrorMessage[];
}

@State<ErrorsStateModel>({
    name: 'errors',
    defaults: {
        errors: []
    }
})
@Injectable()
export class ErrorsState {
    constructor(
        private productService: ProductService,
        private recipeService: RecipeService,
    ) {
    }

    @Selector()
    static getErrors(state: ErrorsStateModel) {
        return state.errors;
    }

    @Select(ErrorsState) errors$: Observable<string[]>;

    @Action(LoadErrors)
    load(ctx: StateContext<ErrorsStateModel>) {
        return forkJoin(
            this.productService.getAllInError(),
            this.recipeService.getAllInError()
        ).pipe(
            map(([productList, recipeList]) => {
                const errors: ErrorMessage[] = [];

                productList.forEach(x => {
                    const error: ErrorMessage = x;
                    error.rootPath = 'product';
                    error.labelType = 'produit';
                    errors.push(error);
                });

                recipeList.forEach(x => {
                    const error: ErrorMessage = x;
                    error.rootPath = 'recipe';
                    error.labelType = 'recette';
                    errors.push(error);
                });

                ctx.setState({
                    errors: errors
                });
            })
        );

        /*return this.productService.getAllInError().pipe(map(data => {
            data.forEach(x => {
                const error: ErrorMessage = x;
                error.rootPath = 'product';
                error.labelType = 'produit';
                errors.push(error);
            });

            ctx.setState({
                errors: errors
            });
        }));*/

        // TODO : ajouter les recettes
    }

}