import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/guard/auth.guard';
import { MenuLayoutComponent } from '../layouts/menu/menu-layout.component';
import { ProductionIndexComponent } from './production-index/production-index.component';
import { ProductionViewComponent } from './production-view/production-view.component';


const productionRoutes: Routes = [
  {
    path: 'production',
    component: MenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductionIndexComponent,
      },
      {
        path: ':id',
        component: ProductionViewComponent,
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(productionRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductionRoutingModule {
}
