import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyToasterService } from '@services/my-toaster.service';
import { Product } from '@shared/models/product';
import { ProductService } from '@services/product.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ButtonService } from '@services/button.service';
import { Provider } from '@shared/models/provider';
import { ProviderService } from '@services/provider.service';

@Component({
  selector: 'app-index',
  templateUrl: './product-index.component.html',
  providers: [MyToasterService, ProductService]
})
export class ProductIndexComponent implements OnInit, OnDestroy {
  list: Product[] = [];
  cols: any[];

  displayDialog: boolean = false;
  disabled: boolean = false;
  productForm: FormGroup;

  submitted: Boolean = false;

  providers: any[];
  providerIdFilter: any;

  private _subscription: Subscription;
  private _subscriptionCrud: Subscription;

  constructor(private breadcrumbService: BreadcrumbService,
    private buttonService: ButtonService,
    private productService: ProductService,
    private providerService: ProviderService,
    private formBuilder: FormBuilder,
    private myToasterService: MyToasterService,
    private router: Router) {

    this.breadcrumbService.setItems([
      { label: 'Produits' }
    ]);
    this.buttonService.reset();
  }

  ngOnInit() {
    this.submitted = false
    this.cols = [
      { field: 'name', header: 'Nom' },
      { field: 'provider', header: 'Fournisseur (par défaut)' },
      { field: 'price', header: 'Prix (par défaut)' },
    ];

    this.search();

    this.providerService.getAll().subscribe(data => {
      this.providers = [];
      this.providers.push({ value: null, label: '--' });
      data.forEach(elem => {
        this.providers.push({ value: elem.id, label: elem.name });
      });
    });

    this.productForm = this.initForm();
  }

  search() {
    this._subscription = this.productService.getAll(this.providerIdFilter ? this.providerIdFilter.value : null)
      .subscribe(
        data => {
          this.list = data;
        },
        error => {
          this.myToasterService.error();
        }
      );
  }

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
    if (this._subscriptionCrud != null) {
      this._subscriptionCrud.unsubscribe();
    }
  }

  showDialogToAdd() {
    this.productForm = this.initForm();
    this.displayDialog = true;
  }

  initForm() {
    const form: FormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      pictureNeeded: [false],
    });
    return form;
  }

  save() {
    this.disabled = true;
    this.submitted = true;
    if (this.productForm.valid) {
      // Save data
      this._subscriptionCrud = this.productService.saveOrUpdateProduct(this.productForm.value)
        .subscribe(
          data => {
            this.myToasterService.success('Produit créé');
            this.displayDialog = false;
            this.disabled = false;
            this.router.navigate(['/product', data.id]);
          },
          error => {
            this.myToasterService.error();
            this.disabled = false;
          }
        );
    }
  }

  printForm() {
    console.log(this.productForm);
  }


}