import { Category } from '@shared/models/category';

export class LoadCategories {
    static readonly type = '[Categories Data] LoadCategories';
}

export class AddCategory {
    static readonly type = '[Categories Data] AddCategory';
    constructor(public category: Category) {}

}