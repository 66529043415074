import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

  // https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
  constructor(private userService: UserService,
    private router: Router,
    public jwtHelper: JwtHelperService) {
  }

  public getToken(): string {
    return localStorage.getItem('id_token');
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  canActivate() {
    if (this.userService.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/user/login']);

      return false;
    }
  }
}
