import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductionService } from '@services/production.service';

@Component({
    selector: 'app-production-create',
    templateUrl: './production-create.component.html',
    styles: [`
    ::ng-deep .p-dialog-resizable .p-dialog-content {
      overflow: unset;
    }
  `]
})
export class ProductionCreateComponent implements OnInit, OnDestroy {
    form: FormGroup;
    
    @Input() idRecipe: number;
    @Input() labelRecipe: string;
    
    displayDialog: boolean;
    submitted: boolean;

    constructor(private formBuilder: FormBuilder,
        private productionService: ProductionService,
        private router: Router) {

    }

    ngOnInit() {
        this.form = this.initForm();
    }

    initForm(): FormGroup {
        const form = this.formBuilder.group({
            num_lot: ['', [Validators.required]],
            date: ['', [Validators.required]]
        });
        
        return form;
    }

    ngOnDestroy() {

    }

    openDialog() {
        this.displayDialog = true;
    }

    save() {
        this.submitted = true;
        if (this.form.valid) {
            this.productionService.save(this.form.value, this.idRecipe.toString()).subscribe(data => {
                this.router.navigate(['/production', data.id]);
            });
        }
    }
}