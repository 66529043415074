import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';

const RESOURCE_PATH = '/product_provider';

@Injectable()
export class ProductProviderService {
    private _refresh = new Subject<void>();

    get refresh() {
        return this._refresh;
    }

    constructor(private apiService: ApiService) {
    }

    getAll(providerId = null, productId = null) {
        if (providerId) {
            return this.apiService.get(RESOURCE_PATH + "?provider=" + providerId);
        }
        if (productId) {
            return this.apiService.get(RESOURCE_PATH + "?product=" + productId);
        }
    }


    remove(id: string|number) {
        return this.apiService.delete(RESOURCE_PATH + '/' + id);
    }

}