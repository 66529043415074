import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CategoryService } from '@services/category.service';
import { Category } from '@shared/models/category';
import { LoadCategories, AddCategory } from './categories.actions';
import { map } from 'rxjs/operators';

export interface CategoriesStateModel {
    categories: Category[];
}

@State<CategoriesStateModel>({
    name: 'categories',
    defaults: {
        categories: []
    }
})
@Injectable()
export class CategoriesState {
    constructor(private categoryService: CategoryService) {
    }

    @Selector()
    static getCategories(state: CategoriesStateModel) {
        return state.categories;
    }

    @Action(LoadCategories)
    load(ctx: StateContext<CategoriesStateModel>) {
        return this.categoryService.findAll().pipe(map(categories => {
            ctx.setState({
                categories: categories
            });
        }));
    }

    @Action(AddCategory)
    add(ctx: StateContext<CategoriesStateModel>, { category }: AddCategory) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            categories: [
              ...state.categories,
              category
            ]
          });
    }

    @Selector()
    static categoryByType(state: CategoriesStateModel) {
        return (type: string) => {
            return state.categories.filter(x => x.type === type);
        };
    }

    @Selector()
    static categoryById(state: CategoriesStateModel) {
        return (id: number) => {
            return state.categories.find(x => x.id === id);
        };
    }

}