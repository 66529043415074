import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Type } from '@shared/models/type';
import { TypesState } from '@shared/store/types/types.state';
import { TypesGroupName } from 'app/core/enum/types.group.name';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TypesStoreService {
    constructor(private store: Store) { }

    public getWithGrp(type: TypesGroupName): Observable<Type[]> {
        return this.store
            .select(TypesState.typesByGroup)
            .pipe(map(filterFn => filterFn(TypesGroupName[type])));
    }

    /*public addNew(type: Type) {
        return this.store.dispatch(new AddType(type));
    }*/
}