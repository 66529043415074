import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutes } from 'app/app.routes';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ProductRoutingModule } from '../product/product-routing.module';
import { ProductionModule } from '../production/production-module';
import { ProviderRoutingModule } from '../provider/provider-routing.module';
import { RecipeRoutingModule } from '../recipe/recipe-routing.module';
import { UserRoutingModule } from '../user/user-routing.module';
import { AnonymousLayoutComponent } from './anonymous/anonymous-layout.component';
import { BreadcrumbComponent } from './menu/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './menu/header/header.component';
import { LeftMenuComponent } from './menu/left-menu/left-menu.component';
import { MenuitemComponent } from './menu/menu-item/menu-item.component';
import { MenuLayoutComponent } from './menu/menu-layout.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule,
    ButtonModule,
    BrowserModule,
    AppRoutes,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    DialogModule,
    InputSwitchModule,
    MenuModule,
    MenubarModule,
    BreadcrumbModule,
    SplitButtonModule,
    OverlayPanelModule,
    MessageModule,
    PanelModule,
    ScrollPanelModule,
    TableModule,
    CardModule,

    ProviderRoutingModule,
    ProductRoutingModule,
    RecipeRoutingModule,
    UserRoutingModule,
    ProductionModule,
  ],
  declarations: [
    AnonymousLayoutComponent,
    BreadcrumbComponent,
    MenuLayoutComponent,
    HeaderComponent,
    LeftMenuComponent,
    MenuitemComponent,
  ]
})
export class LayoutModule {
}
