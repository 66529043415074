import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

//import {ProfileComponent} from './profile/profile.component';
import {AuthenticationComponent} from './authentication/authentication.component';
//import {RegisterComponent} from './register/register.component';
//import {ForgotComponent} from './forgot/forgot.component';
//import {WaitingEmailComponent} from './waiting-email/waiting-email.component';
import {AnonymousLayoutComponent} from '../layouts/anonymous/anonymous-layout.component';
import {MenuLayoutComponent} from '../layouts/menu/menu-layout.component';
import { AuthGuard } from 'app/core/guard/auth.guard';
import { SettingsComponent } from './settings/settings.component';
import { LoadingComponent } from './loading/loading.component';
//import {SettingsComponent} from './settings/settings.component';
//import {ProjectComponent} from './project/project.component';

const userRoutes: Routes = [
  {
    path: 'user',
    component: AnonymousLayoutComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationComponent
      },
      {
        path: 'loading',
        component: LoadingComponent
      }
      /*{
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forgot',
        component: ForgotComponent
      },
      {
        path: 'waiting',
        component: WaitingEmailComponent
      },*/
    ]
  },
  {
    path: 'user',
    component: MenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      /*{
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },*/
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard]
      },
      /*{
        path: 'project',
        component: ProjectComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'project/:id',
        component: ProjectComponent,
        canActivate: [AuthGuard]
      }*/
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule {
}
