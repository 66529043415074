import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MyToasterService } from '@services/my-toaster.service';
import { RecipeService } from '@services/recipe.service';
import { Recipe } from '@shared/models/recipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './recipe-index.component.html',
  providers: [MyToasterService, RecipeService],
})
export class RecipeIndexComponent implements OnInit {
  list: Recipe[] = [];
  cols: any[];

  displayDialog: boolean = false;
  disabled: boolean = false;
  recipeForm: FormGroup;

  submitted: Boolean = false;

  private _subscription: Subscription;
  private _subscriptionCrud: Subscription;

  constructor(private breadcrumbService: BreadcrumbService,
    private buttonService: ButtonService,
    private recipeService: RecipeService,
    private formBuilder: FormBuilder,
    private myToasterService: MyToasterService,
    private router: Router) {
    this.breadcrumbService.setItems([
      { label: 'Recette' }
    ]);
    this.buttonService.reset();
  }

  ngOnInit() {
    this.submitted = false;
    this.cols = [
      { field: 'name', header: 'Nom' },
      { field: 'netPrice', header: 'Prix Net' },
    ];

    this._subscription = this.recipeService.getAll()
      .subscribe(
        data => {
          this.list = data;
        },
        error => {
          this.myToasterService.error();
        }
      );

    this.recipeForm = this.initForm();
  }

  showDialogToAdd() {
    this.recipeForm = this.initForm();
    this.displayDialog = true;
  }

  initForm() {
    this.submitted = false;
    const form: FormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      pictureNeeded: [false],
      description: [''],
      date: []
    });
    return form;
  }

  save() {
    this.disabled = true;
    this.submitted = true;
    if (this.recipeForm.valid) {
      // Save data
      this._subscriptionCrud = this.recipeService.saveOrUpdateRecipe(this.recipeForm.value)
        .subscribe(
          data => {
            this.myToasterService.success('Recette créée');
            this.displayDialog = false;
            this.disabled = false;
            this.router.navigate(['/recipe', data.id]);
          },
          error => {
            this.myToasterService.error();
            this.disabled = false;
          }
        );
    }
  }
}