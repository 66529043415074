import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ProductionCreateComponent } from './production-create/production-create.component';
import { ProductionIndexComponent } from './production-index/production-index.component';
import { ProductionRoutingModule } from './production-routing.module';
import { ProductionViewLineComponent } from './production-view-line/production-view-line.component';
import { ProductionViewComponent } from './production-view/production-view.component';
import { GalleriaModule } from 'primeng/galleria';
import { StepsModule } from 'primeng/steps';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule,
    TableModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    SplitButtonModule,
    CardModule,
    TabViewModule,
    ConfirmDialogModule,
    CalendarModule,
    ProgressBarModule,
    GalleriaModule,
    StepsModule,
    AccordionModule,

    SharedModule,
    ProductionRoutingModule,
    MessagesModule,
  ],
  declarations: [
    ProductionIndexComponent,
    ProductionViewComponent,
    ProductionViewLineComponent,
    ProductionCreateComponent,
  ],
  exports: [
    ProductionCreateComponent,
  ]
})
export class ProductionModule {
}
