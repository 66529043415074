import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Parameter } from '@shared/models/parameter';
import { ParametersState } from '@shared/store/parameters/parameters.state';
import { ParameterName } from 'app/core/enum/parameter.name';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ParameterStoreService {
    constructor(private store: Store) {}

    getAll(): Observable<Parameter[]> {
        return this.store.select(state => state.parameters.parameters);
    }

    public getValue(paramName: ParameterName): Observable<Parameter> {
        return this.store
            .select(ParametersState.parameterByName)
            .pipe(map(filterFn => filterFn(ParameterName[paramName])));
    }
}