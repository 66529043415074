import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Recipe } from '@shared/models/recipe';
import { ApiService } from '@services/api.service';

const RESOURCE_PATH = '/sheets';

@Injectable()
export class RecipeService {
  private _refresh = new Subject<void>();

  get refresh() {
    return this._refresh;
  }

  constructor(private apiService: ApiService) {
  }

  get(id: string | number) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + id);
  }

  getAll() {
    return this.apiService
      .get(RESOURCE_PATH);
  }

  getAllInError() {
    return this.apiService.get(RESOURCE_PATH + '/error');
  }

  getItems(recipeId) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + recipeId + '/items/');
  }

  saveOrUpdateRecipe(recipe: Recipe) {
    if (recipe.id) {
      return this.apiService
        .put(RESOURCE_PATH + '/' + recipe.id, recipe).pipe(
          tap(() => {
            this.refresh.next();
          })
        );
    } else {
      return this.apiService
        .post(RESOURCE_PATH, recipe);
    }
  }

  calculateNutrition(recipe: Recipe) {
    return this.apiService
      .post(RESOURCE_PATH + '/' + recipe.id + '/nutrition', recipe);
  }

  remove(id: string) {
    return this.apiService.delete(RESOURCE_PATH + '/' + id);
  }
}
