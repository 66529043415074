import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { TypesStoreService } from '@services/store/types.store.service';
import { Type } from '@shared/models/type';
import { TypesGroupName } from 'app/core/enum/types.group.name';
import { CategoryStoreService } from '@services/store/category.store.service';
import { CategoryName } from 'app/core/enum/category.name';
import { Category } from '@shared/models/category';
import { CategoryService } from '@services/category.service';

@Component({
    selector: 'app-nutri-detail',
    templateUrl: './nutri-detail.component.html'
})
export class NutriDetailComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() id: number;
    @Output() delete = new EventEmitter<number>();
    displayDialog: boolean = false;

    allergenList: Type[];

    submitted: Boolean = false;
    categories: string[] = [];

    constructor(private controlContainer: ControlContainer,
        private typeStoreService: TypesStoreService,
        private categoryService: CategoryService,
        private categoryStoreService: CategoryStoreService) {
    }

    ngOnInit() {
        this.submitted = false;
        this.typeStoreService.getWithGrp(TypesGroupName.allergenValue).subscribe(data => this.allergenList = data);

        this.categoryStoreService.getWithType(CategoryName.NUTRI).subscribe(data => {
            data.forEach(x => {
                this.categories.push(x.label);
            });
        });

        this.form = <FormGroup>this.controlContainer.control;
        this.displayDialog = true;
        if (this.form.controls.id.value) {
            this.displayDialog = false;
        } else if (this.id != -1) {
            this.displayDialog = true;
        }
    }

    get label() {
        return this.form.controls.label.value;
    }

    searchCategories(event) {
        this.categoryStoreService.getWithType(CategoryName.NUTRI).subscribe(data => {
            this.categories = [];
            data.forEach(x => {
                if (x.label.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                    this.categories.push(x.label);
                }
            })
        });
    }

    deleteNutri() {
        this.displayDialog = false;
        this.delete.emit(this.id);
    }

    openModal() {
        this.displayDialog = true;
        this.submitted = false;
    }

    closeModal() {
        this.submitted = true;
        if (this.form.valid) {
            this.categoryStoreService.getWithType(CategoryName.NUTRI).subscribe(datas => {
                const cat = datas.find(x => x.label === this.form.controls.label.value);
                if (!cat) {
                    var category = new Category();
                    category.type = 'NUTRI';
                    category.label = this.form.controls.label.value;
                    this.categoryService.create(category).subscribe(data => {
                        category = data;
                        this.categoryStoreService.addNew(category);
                    })
                }
            });

            this.displayDialog = false;
        }
    }

}