import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadCategories } from '@shared/store/categories/categories.actions';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { LoadParameters } from '@shared/store/parameters/parameters.actions';
import { LoadTypes } from '@shared/store/types/types.actions';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnInit {

    constructor(private store: Store,
        private router: Router) {
    }

    ngOnInit() {
        this.store.dispatch([
            new LoadParameters(),
            new LoadCategories(),
            new LoadTypes()
        ]).subscribe(() => {
            this.router.navigate(['/home']);
        });

    }
}