import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MyToasterService } from '@services/my-toaster.service';
import { ProductionService } from '@services/production.service';
import { Recipe } from '@shared/models/recipe';
import { RecipeItem } from '@shared/models/recipeItem';
import { RecipeFormService } from 'app/modules/recipe/recipe-form.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-index',
    templateUrl: './production-view.component.html',
    providers: [MyToasterService, RecipeFormService, ConfirmationService],
})
export class ProductionViewComponent implements OnInit {
    form: FormGroup;
    recipe: Recipe;

    loading: boolean;
    submitted: boolean = false;

    private subscriptionRepository: Subscription;

    constructor(private breadcrumbService: BreadcrumbService,
        private formBuilder: FormBuilder,
        private buttonService: ButtonService,
        private productionService: ProductionService,
        private route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        private myToasterService: MyToasterService,
    ) {
        this.breadcrumbService.setItems([
            { label: 'Production', routerLink: ['/production'] }
        ]);

        const menu: MenuItem[] = [
            { label: 'Cancel', icon: 'pi pi-refresh', command: () => { this.cancel(); } },
            { label: 'Delete', icon: 'pi pi-trash', command: () => { this.remove(); } },
            { label: 'Log form', icon: 'pi pi-print', command: () => { console.log(this.form); } },
        ];
        const mainMenu: MenuItem = { label: 'Save', icon: 'pi pi-refresh', command: () => { this.save(); } };
        this.buttonService.setItems(mainMenu, menu);
    }

    ngOnInit() {
        this.loading = true;
        this.form = this.initForm(null);

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.productionService.get(id).subscribe(data => {
                this.recipe = data.recipe;
                this.form = this.initForm(data);

                this.loading = false;
            });

        }


    }

    initForm(data: any): FormGroup {
        const form = this.formBuilder.group({
            id: [''],
            num_lot: ['', [Validators.required]],
            date: ['', [Validators.required]]
        });
        const pipe = new DatePipe('en-US');
        if (data) {
            form.patchValue(data);
            form.patchValue({
                date: pipe.transform(data.date, 'dd/MM/y')
            });

        }

        return form;
    }

    get itemsFormArray(): FormArray {
        return this.form.get('items') as FormArray;
    }

    itemsFormArrayAt(id: number): FormGroup {
        return this.itemsFormArray.at(id) as FormGroup;
    }

    addItem(item: RecipeItem) {
        const itemForm = this.formBuilder.group({
            label: item.name,
            pictures: this.formBuilder.array([])
        });
        this.itemsFormArray.push(itemForm);
    }

    save() {
        // TODO
    }

    cancel() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur d\'annuler les modifications ?',
            accept: () => {
                this.ngOnInit();
            }
        });
    }

    remove() {
        // TODO
    }

    generatePdf() {
        this.confirmationService.confirm({
            message: 'Souhaitez-vous également valider la fiche recette (<b>seul le PDF sera par la suite disponible</b>) ?',
            acceptLabel: 'Bloquer la fiche',
            acceptButtonStyleClass: 'p-button-danger',
            rejectLabel: 'Visualiser le PDF',
            rejectIcon: 'pi pi-file-pdf',
            accept: () => {
                console.log('Accepté');
            },
            reject: () => {
                this.openPdf();
            }
        });
    }

    openPdf() {
        const productionId = this.route.snapshot.paramMap.get('id');
        this.productionService.getPdf(productionId).subscribe(
            (data: Blob) => {
            //const res = data, { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(data);
            window.open(fileURL, '_blank');
        })
    }

}