import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ProductIndexComponent } from './product-index/product-index.component';
import { ProductRoutingModule } from './product-routing.module';
import { ProductViewComponent } from './product-view/product-view.component';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule,
    TableModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    SplitButtonModule,
    CardModule,
    ConfirmDialogModule,
    FieldsetModule,
    ProgressBarModule,
    
    SharedModule,
    ProductRoutingModule,
  ],
  declarations: [
    ProductIndexComponent,
    ProductViewComponent,
  ],
})
export class ProductModule {
}
