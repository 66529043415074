import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { MyToasterService } from '@services/my-toaster.service';
import { ProductService } from '@services/product.service';
import { ProviderService } from '@services/provider.service';
import { Nutrition } from '@shared/models/nutrition';
import { Product } from '@shared/models/product';
import { ProductProvider } from '@shared/models/product.provider';
import { Provider } from '@shared/models/provider';
import { NutriFormService } from '@shared/nutri/nutri-form.service';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import ValidatorsUtils from 'app/core/utils/validators-utils';
import { Subscription } from 'rxjs';

@Injectable()
export class ProductFormService implements OnDestroy {
    form: FormGroup;

    providers: Provider[] = [];

    private subscriptionProvider: Subscription;

    constructor(private formBuilder: FormBuilder,
        private providerService: ProviderService,
        private productService: ProductService,
        private nutriFormService: NutriFormService,
        private myToasterService: MyToasterService,
        private store: Store,
    ) {
        this.form = this.formBuilder.group({
            id: [''],
            name: ['', [Validators.required]],
            picture_needed: [false],
            active: [true],
            providers: this.formBuilder.array([]),
            categories: [''],
            nutri: this.formBuilder.array([]),
        });

        this.subscriptionProvider = this.providerService
            .getAll()
            .subscribe(
                data => {
                    this.providers = data;
                });
    }

    ngOnDestroy(): void {
        if (this.subscriptionProvider != null) {
            this.subscriptionProvider.unsubscribe();
        }
    }

    initForm(data: Product) {
        this.form.reset();
        const nutriArray = this.nutriArray;
        while (nutriArray.length !== 0) {
            nutriArray.removeAt(0);
        }

        const providers = this.providersArray;
        while (providers.length !== 0) {
            providers.removeAt(0);
        }

        this.form.patchValue(data);

        if (data) {
            if (data.nutri) {
                for (let line = 0; line < data.nutri.length; line++) {
                    this.addNutri(data.nutri[line]);
                }
            }

            if (data.product_providers) {
                for (let line = 0; line < data.product_providers.length; line++) {
                    this.addProvider(data.product_providers[line]);

                    if (data.product_providers[line].nutri) {
                        for (let lineNutri = 0; lineNutri < data.product_providers[line].nutri.length; lineNutri++) {
                            this.addProviderNutri(line, data.product_providers[line].nutri[lineNutri]);
                        }
                    }
                }
            }

        }
    }

    /******************************
     * provider management
     ******************************/

    getProviderFormGroup(productProvider: ProductProvider): FormGroup {
        const result = this.formBuilder.group({
            id: [''],
            idProvider: ['', [Validators.required]],
            idContent: ['', [Validators.required]],
            designation: [''],
            price: ['', [Validators.required, Validators.pattern(ValidatorsUtils.numberPattern())]],
            byDefault: [false],
            active: [true],
            nutri: this.formBuilder.array([]),
        });

        if (productProvider) {
            result.patchValue({
                id: productProvider.id,
                active: productProvider.active,
                designation: productProvider.designation,
                byDefault: productProvider.by_default,
                idProvider: new FormControl(productProvider.provider.id),
                idContent: new FormControl(productProvider.price.packaging_type_id),
                price: productProvider.price.net_price
            });
        }

        return result;
    }

    get providersArray(): FormArray {
        return this.form.get('providers') as FormArray;
    }

    addProvider(data: any) {
        this.providersArray.push(this.getProviderFormGroup(data));
    }

    removeProvider(id: number) {
        if (this.providersArray.at(id).get('id').value) {
            this.providersArray.at(id).patchValue({
                active: false
            });
        } else {
            this.providersArray.removeAt(id);
        }
    }

    /******************************
     * nutri management
     ******************************/

    get nutriArray(): FormArray {
        return this.form.get('nutri') as FormArray;
    }

    addNutri(data: Nutrition) {
        this.nutriArray.push(this.nutriFormService.getNutriFormGroup(data));
    }

    private providerNutri(idProvider: number): FormArray {
        return <FormArray>this.providersArray.at(idProvider).get('nutri');
    }

    addProviderNutri(idProvider: number, data: Nutrition) {
        this.providerNutri(idProvider).push(this.nutriFormService.getNutriFormGroup(data));
    }

    removeNutri(id: number) {
        this.nutriArray.removeAt(id);
    }

    removeProviderNutri(idProvider: number, id: number) {
        this.providerNutri(idProvider).removeAt(id);
    }

    save() {
        this.productService.saveOrUpdateProduct(this.form.value).toPromise().then(
            data => {
                this.initForm(data);
                this.myToasterService.success("Produit sauvegardé.");
                this.store.dispatch([new LoadErrors()]);
            },
            error => {
                this.myToasterService.error();
            }
        );
    }

}
