import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {UserRoutingModule} from './user-routing.module';

import {AuthenticationComponent} from './authentication/authentication.component';
import { LayoutModule } from '../layouts/layout.module';
import { CoreModule } from 'app/core/core-module';
import { SettingsComponent } from './settings/settings.component';
import { LoadingComponent } from './loading/loading.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    CardModule,
    SplitButtonModule,
    ConfirmDialogModule,
    ProgressBarModule,
    
    UserRoutingModule,
    LayoutModule,
    

  ],
  declarations: [
    AuthenticationComponent,
    LoadingComponent,
    //RegisterComponent,
    //ForgotComponent,
    //WaitingEmailComponent,
    //ProfileComponent,
    SettingsComponent,
    //SettingsLineComponent,
    //ProjectComponent,
    //ProjectDescriptionComponent,
  ]
})
export class UserModule {
}
