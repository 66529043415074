import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyToasterService } from '@services/my-toaster.service';
import { ProviderService } from '@services/provider.service';
import { RecipeService } from '@services/recipe.service';
import { ParameterStoreService } from '@services/store/parameter.store.service';
import { Parameter } from '@shared/models/parameter';
import { Provider } from '@shared/models/provider';
import { Recipe } from '@shared/models/recipe';
import { RecipeItem } from '@shared/models/recipeItem';
import { NutriFormService } from '@shared/nutri/nutri-form.service';
import { ParameterName } from 'app/core/enum/parameter.name';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import ValidatorsUtils from '../../core/utils/validators-utils';
import { Item } from './item';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { Store } from '@ngxs/store';

@Injectable()
export class RecipeFormService implements OnDestroy {
    form: FormGroup;

    providers: Provider[] = [];
    items: Item[] = [];

    defaultAlea: Parameter;
    defaultCoef: Parameter;
    defaultTva: Parameter;

    private subscriptionProvider: Subscription;
    private subscriptionItems: Subscription;

    constructor(private formBuilder: FormBuilder,
        private providerService: ProviderService,
        private recipeService: RecipeService,
        private nutriFormService: NutriFormService,
        private parameterStoreService: ParameterStoreService,
        private myToasterService: MyToasterService,
        private store: Store,
    ) {

        this.form = this.formBuilder.group({
            id: [''],
            name: ['', [Validators.required]],
            picture_needed: [false],
            description: [''],
            active: ['true'],
            coef: ['', [Validators.required]],
            tva: ['', [Validators.required]],
            alea: ['', [Validators.required]],
            prices: this.formBuilder.group({
                totalCost: [''],
                quantity: ['', [Validators.required]],
                packagingId: ['', [Validators.required]],
                gross_price: ['', [Validators.required]],
                net_price: ['', [Validators.required]]
            }),
            categories: [''],
            nutri: this.formBuilder.array([]),
            providers: this.formBuilder.array([]),
        });

        this.subscriptionProvider = this.providerService
            .getAll()
            .subscribe(
                data => {
                    this.providers = data;
                });
    }

    ngOnDestroy(): void {
        if (this.subscriptionProvider != null) {
            this.subscriptionProvider.unsubscribe();
        }
        if (this.subscriptionItems != null) {
            this.subscriptionItems.unsubscribe();
        }
    }


    initForm(elem: Recipe) {

        combineLatest(
            this.parameterStoreService.getValue(ParameterName.DEFAULT_ALEA),
            this.parameterStoreService.getValue(ParameterName.DEFAULT_COEF),
            this.parameterStoreService.getValue(ParameterName.DEFAULT_TVA))
            .pipe(map(([alea, coef, tva]) => {
                this.defaultAlea = alea;
                this.defaultCoef = coef;
                this.defaultTva = tva;
            })).subscribe(() => {
                if (elem) {
                    this.form.patchValue(elem);

                    this.form.patchValue({
                        alea: elem.alea ? elem.alea : Parameter.usableValue(this.defaultAlea),
                        coef: elem.coef ? elem.coef : Parameter.usableValue(this.defaultCoef),
                        tva: elem.tva ? elem.tva : Parameter.usableValue(this.defaultTva),
                        prices: {
                            packagingId: elem.prices ? new FormControl(elem.prices.packaging_type_id) : null,
                        }
                    });

                    if (elem.nutri && elem.nutri[0]) {
                        console.log(elem.nutri[0]);
                        this.nutriForm.push(this.nutriFormService.getNutriFormGroup(elem.nutri[0]));
                    }

                    if (elem.items) {
                        for (let line = 0; line < elem.items.length; line++) {
                            this.addProduct(elem.items[line]);
                        }
                    }

                }

                return this.form;
            });

    }

    initItems(sheetId: number | string) {
        this.subscriptionItems = this.recipeService
            .getItems(sheetId)
            .subscribe(
                data => {
                    this.items = data;
                });
    }

    get nutriForm(): FormArray {
        return this.form.get('nutri') as FormArray;
    }

    get providersArray(): FormArray {
        return this.form.get('providers') as FormArray;
    }

    get pricesGroup(): FormGroup {
        return this.form.get('prices') as FormGroup;
    }

    addProduct(recipeItem: RecipeItem) {
        const formGroup = this.formBuilder.group({
            idItem: ['', [Validators.required]],
            idProvider: [],
            grossQty: ['', [Validators.required, Validators.pattern(ValidatorsUtils.numberPattern())]],
            netQty: ['', [Validators.required, Validators.pattern(ValidatorsUtils.numberPattern())]],
            price: [''],
            nutriTypeId: ['', [Validators.required]],
            showPercent: false,
            active: [true],
        });

        if (recipeItem) {
            formGroup.patchValue({
                idItem: recipeItem.id,
                idProvider: recipeItem.provider,
                grossQty: recipeItem.gross_qty,
                netQty: recipeItem.net_qty,
                price: recipeItem.price,
                showPercent: recipeItem.show_percent,
                nutriTypeId: recipeItem.nutri_type_id ? recipeItem.nutri_type_id.id : '',
            });
        }

        formGroup.get('price').disable();


        this.providersArray.push(formGroup);
    }


    removeItem(id: number) {
        this.providersArray.removeAt(id);
    }

    deleteNutri() {
        if (this.nutriForm.length > 0) {
            this.nutriForm.removeAt(0);
        }
    }

    calculateNutrition() {
        this.recipeService.calculateNutrition(this.form.value).toPromise().then(data => {
            if (this.nutriForm.length > 0) {
                this.nutriForm.removeAt(0);
            }
            this.nutriForm.push(this.nutriFormService.getNutriFormGroup(data));
        })
    }

    save() {
        this.recipeService.saveOrUpdateRecipe(this.form.value).toPromise().then(
            data => {
                this.myToasterService.success('La recette a bien été mise à jour');
                this.store.dispatch([new LoadErrors()]);
            }, error => {
                this.myToasterService.error();
            }
        );
    }

    removeRecipe(id: number) {
        if (this.providersArray.at(id).get('id').value) {
            this.providersArray.at(id).patchValue({
                active: false
            });
        } else {
            this.providersArray.removeAt(id);
        }
    }
}