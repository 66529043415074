import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyToasterService } from '@services/my-toaster.service';
import { Production } from '@shared/models/production';
import { ProductionService } from '@services/production.service';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-index',
    templateUrl: './production-index.component.html',
    providers: [MyToasterService]
})
export class ProductionIndexComponent implements OnInit, OnDestroy {
    list: Production[] = [];
    cols: any[];

    private _subscription: Subscription;

    constructor(private breadcrumbService: BreadcrumbService,
        private buttonService: ButtonService,
        private productionService: ProductionService,
        private myToasterService: MyToasterService,
    ) {
        this.breadcrumbService.setItems([
            { label: 'Productions' }
        ]);
        this.buttonService.reset();
    }

    ngOnInit() {
        this.cols = [
            { field: 'recipe', header: 'Recette' },
            { field: 'date', header: 'Date' },
            { field: 'numLot', header: 'Numéro de Lot' },
        ];

        this.search();
    }

    search() {
        this._subscription = this.productionService.getAll()
            .subscribe(
                data => {
                    this.list = data;
                },
                error => {
                    this.myToasterService.error();
                }
            );
    }

    ngOnDestroy() {
        if (this._subscription != null) {
            this._subscription.unsubscribe();
          }
    }


}