import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '@services/category.service';
import { MyToasterService } from '@services/my-toaster.service';
import { CategoryStoreService } from '@services/store/category.store.service';
import { Category } from '@shared/models/category';
import { CategoryName } from 'app/core/enum/category.name';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  providers: [MyToasterService],
  styles: [`
        :host ::ng-deep .ui-multiselected-item-token,
        :host ::ng-deep .ui-multiselected-empty-token {
            padding: 2px 4px;
            margin: 0 0.286em 0 0;
            display: inline-block;
            vertical-align:middle;
            height: 1.857em;
        }

        :host ::ng-deep .ui-multiselected-item-token {
            background: #39a3f4;
            color: #ffffff;
        }
    `]
})
export class CategoriesComponent implements OnInit {
  categoriesList: any[] = [];

  @Input() form: FormGroup;
  @Input() type: string;

  addForm: FormGroup;

  constructor(private categoryService: CategoryService,
    private categoryStoreService: CategoryStoreService,
    private formBuilder: FormBuilder,
    private myToasterService: MyToasterService
  ) {
    this.form = this.formBuilder.group({
      categories: ''
    });

    this.addForm = this.formBuilder.group({
      newValue: ['', Validators.required]
    })

  }

  ngOnInit() {
    // TODO : peut mieux faire avec un Observable
    this.categoryStoreService.getWithType(CategoryName[this.type])
      .subscribe(
        data => {
          const result = [];
          data.forEach(elem => {
            result.push({ label: elem.label, value: { id: elem.id, name: elem.label } });
          });
          this.categoriesList = result;
        }
      );
  }

  addToList() {
    let category: Category = new Category();
    category.label = this.addForm.controls.newValue.value;
    category.type = this.type;
    this.categoryService.create(category).subscribe(data => {
      category = data;
      this.categoryStoreService.addNew(category).subscribe(() => {
        this.addForm.reset();

        this.ngOnInit();
        this.myToasterService.success("Catégorie ajoutée avec succès.");
      })
    })
  }

}
