import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiService} from '@services/api.service';
import LocalStorage from '../utils/local-storage';
import {Project} from '@shared/models/project';
import {tap} from 'rxjs/operators';

const RESOURCE_PATH = '/projects';

@Injectable()
export class ProjectService {
  private _refresh = new Subject<void>();

  get refresh() {
    return this._refresh;
  }

  constructor(private apiService: ApiService) {
  }

  getCurrentProject(): Project {
    return LocalStorage.getObjectFromLocalStorage('current_project');
  }

  setCurrentProject(project: Project) {
    LocalStorage.setObjectInLocalStorage('current_project', project);
  }

  get(id: string | number) {
    return this.apiService
      .get(RESOURCE_PATH + '/' + id);
  }

  getAll() {
    return this.apiService
      .get(RESOURCE_PATH);
  }

  saveOrUpdate(project: any) {
    console.log(project);
    if (project.id) {
      return this.apiService
        .put(RESOURCE_PATH + '/' + project.id, project).pipe(
          tap(() => {
            this.refresh.next();
          })
        );
    } else {
      return this.apiService
        .post(RESOURCE_PATH, project);
    }
  }

}
