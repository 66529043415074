import { NgModule } from '@angular/core';
import { ApiService } from '@services/api.service';
import { ProviderService } from '@services/provider.service';
import { UserService } from '@services/user.service';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { MenuService } from '@services/menu.service';
import { AuthGuard } from './guard/auth.guard';
import { ProjectService } from '@services/project.service';
import { MyToasterService } from '@services/my-toaster.service';
import { TypeService } from '@services/type.service';
import { ProductService } from '@services/product.service';
import { RecipeService } from '@services/recipe.service';
import { CategoryService } from '@services/category.service';
import { ParameterService } from '@services/parameter.service';
import { CategoryStoreService } from '@services/store/category.store.service';
import { ParameterStoreService } from '@services/store/parameter.store.service';
import { TypesStoreService } from '@services/store/types.store.service';
import { ButtonService } from '@services/button.service';
import { ProductionService } from '@services/production.service';
import { MessageService } from 'primeng/api';
import { ErrorsStoreService } from '@services/store/errors.store.service';
import { ProductProviderService } from '@services/product.provider.service';

@NgModule({
    imports: [
    ],
    declarations: [],
    exports: [],
    providers: [
        AuthGuard,
        MenuService,
        BreadcrumbService,
        ButtonService,
        ApiService,
        UserService,
        ProjectService,
        ParameterService,
        TypeService,
        ProviderService,
        RecipeService,
        ProductionService,
        ProductService,
        MyToasterService,
        MessageService,
        CategoryService,
        ProductProviderService,

        CategoryStoreService,
        ParameterStoreService,
        TypesStoreService,
        ErrorsStoreService,
    ]
})
export class CoreModule {
}
