import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { RecipeIndexComponent } from './recipe-index/recipe-index.component';
import { MenuLayoutComponent } from '../layouts/menu/menu-layout.component';
import { AuthGuard } from 'app/core/guard/auth.guard';
import { RecipeViewComponent } from './recipe-view/recipe-view.component';
import { ProductionViewComponent } from '../production/production-view/production-view.component';

const recipeRoutes: Routes = [
  {
    path: 'recipe',
    component: MenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RecipeIndexComponent,
      },
      {
        path: ':id',
        component: RecipeViewComponent,
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(recipeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RecipeRoutingModule {
}
