
export default class LocalStorage {

    static getObjectFromLocalStorage(key: string): any {
      if (localStorage.getItem(key)) {
        return JSON.parse(localStorage.getItem(key));
      }
      return null;
    }
  
    static setObjectInLocalStorage(key: string, object: any) {
      if (object) {
        localStorage.setItem(key, JSON.stringify(object));
      } else {
        localStorage.removeItem(key);
      }
    }
  }
  