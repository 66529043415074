import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { CategoriesComponent } from './component/categories/categories.component';
import { NutriDetailComponent } from './nutri/nutri-detail/nutri-detail.component';
import { NutriFormService } from './nutri/nutri-form.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    ButtonModule,
    MessagesModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    SplitButtonModule,
    TabViewModule,
    EditorModule,
    MultiSelectModule,
    InputTextModule,
    MessageModule,
    AutoCompleteModule,
    InputSwitchModule,
    CardModule,
    DataViewModule,

  ],
  declarations: [
    NutriDetailComponent,
    CategoriesComponent,
  ],
  exports: [
    NutriDetailComponent,
    CategoriesComponent,
  ],
  providers: [
    NutriFormService,
  ]
})
export class SharedModule {
}
