import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { MyToasterService } from '@services/my-toaster.service';
import { ProviderService } from '@services/provider.service';
import { Provider } from '@shared/models/provider';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './provider-index.component.html',
  providers: [MyToasterService, ConfirmationService]
})
export class ProviderIndexComponent implements OnInit {
  list: Provider[] = [];
  cols: any[];

  displayDialog: boolean = false;
  disabled: boolean = false;
  providerForm: FormGroup;

  submitted: Boolean = false;

  private _subscription: Subscription;

  constructor(private breadcrumbService: BreadcrumbService,
    private providerService: ProviderService,
    private formBuilder: FormBuilder,
    public confirmationService: ConfirmationService,
    private myToasterService: MyToasterService,
    private router: Router) {

    this.breadcrumbService.setItems([
      { label: 'Fournisseurs' }
    ]);

    this.providerForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.cols = [
      { field: 'name', header: 'Nom' },
      { field: 'city', header: 'Ville' },
    ];

    this._subscription = this.providerService.getAll()
      .subscribe(
        data => {
          this.list = data;
        },
        error => {
          //this.error = error.message;
        }
      );

    this.initForm();
  }

  showDialogToAdd() {
    this.initForm();
    this.displayDialog = true;
  }

  initForm() {
    this.providerForm.reset();
  }

  save() {
    this.disabled = true;
    this.submitted = true;
    if (this.providerForm.valid) {
      const id = this.providerForm.controls['id'].value;

      // Save data
      this._subscription = this.providerService.saveOrUpdate(this.providerForm.value)
        .subscribe(
          data => {
            this.myToasterService.success('Fournisseur ' + (id ? 'mis à jour' : 'ajouté'));
            this.displayDialog = false;
            this.disabled = false;
            this.submitted = false;
            this.router.navigate(['/provider', data.id]);
          },
          error => {
            this.myToasterService.error();
            this.disabled = false;
          }
        );
    } else {
      this.disabled = false;
    }
  }

 
}
