import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { UserService } from '@services/user.service';
import { Router } from '@angular/router';
import { ErrorMessage } from '@shared/models/error.message';
import { ProductService } from '@services/product.service';
import { RecipeService } from '@services/recipe.service';
import { Store } from '@ngxs/store';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { ErrorsStoreService } from '@services/store/errors.store.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './header.component.html',
    styles: [`
    :host ::ng-deep .p-card-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    `]
})
export class HeaderComponent implements OnInit {
    errorList: Array<ErrorMessage>;

    errorList$: Observable<ErrorMessage[]>;

    constructor(public app: AppComponent,
        private userService: UserService,
        private store: Store,
        public router: Router) {

        this.errorList$ = this.store.select(state => state.errors.errors);
    }

    ngOnInit() {

    }

    logout() {
        this.app.topbarMenuActive = false;
        this.userService.logout();
        this.router.navigate(['/user/login']);
    }

    settings() {
        this.app.topbarMenuActive = false;
        this.router.navigate(['/user/settings']);
    }
}
