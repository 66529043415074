import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnDestroy {
    subscriptionBreadcrumb: Subscription;
    subscriptionButton: Subscription;
    subscriptionMainButton: Subscription;

    breadcrumbItems: MenuItem[];
    home: MenuItem;

    buttonItems: MenuItem[];
    mainItem: MenuItem;

    constructor(public breadcrumbService: BreadcrumbService,
        public buttonService: ButtonService) {
        this.subscriptionBreadcrumb = breadcrumbService.itemsHandler.subscribe(response => {
            this.breadcrumbItems = response;
        });

        this.home = {
            icon: 'pi pi-home', routerLink: ['/']
        };

        this.subscriptionButton = buttonService.buttonsHandler.subscribe(response => {
            this.buttonItems = response;
        });
        this.subscriptionMainButton = buttonService.mainHandler.subscribe(response => {
            this.mainItem = response;
        });
    }

    executeMainItem() {
        if (this.mainItem) {
            this.mainItem.command();
        }
    }

    ngOnDestroy() {
        if (this.subscriptionBreadcrumb) {
            this.subscriptionBreadcrumb.unsubscribe();
        }
        if (this.subscriptionButton) {
            this.subscriptionButton.unsubscribe();
        }
        if (this.subscriptionMainButton) {
            this.subscriptionMainButton.unsubscribe();
        }
    }
}
