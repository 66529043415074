import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { MyToasterService } from '@services/my-toaster.service';
import { ParameterService } from '@services/parameter.service';
import { Parameter } from '@shared/models/parameter';
import { ParametersState } from '@shared/store/parameters/parameters.state';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    providers: [ConfirmationService]
})
export class SettingsComponent implements OnInit, OnDestroy {
    form: FormGroup;
    loading: Boolean;

    parameter: Parameter;

    buttonItems: MenuItem[];

    private _subscription: Subscription;

    constructor(private breadcrumbService: BreadcrumbService,
        private formBuilder: FormBuilder,
        private parameterService: ParameterService,
        private myToasterService: MyToasterService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private store: Store) {

        this.breadcrumbService.setItems([
            { label: 'Préférences' }
        ]);

        this.buttonItems = [
            {
                label: 'Cancel', icon: 'pi pi-refresh', command: () => { this.cancel(); }
            },
            {
                label: 'Log form', icon: 'pi pi-print', command: () => { console.log(this.form); }
            },
        ];

        this.form = this.formBuilder.group({
            parameters: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.store.select(ParametersState.parameterByName)
            .pipe(map(filterFn => filterFn('DEFAULT_ALEA'))).subscribe(x => this.parameter = x);

        //this.parameterService.getValue(ParameterName.DEFAULT_ALEA);

        this._subscription = this.parameterService.findAll()
            .subscribe(
                data => {
                    if (data) {
                        data.forEach(element => {
                            this.addParameter(element);
                        });
                    }
                },
                error => {
                    this.myToasterService.error();
                }
            );
    }

    get parametersArray(): FormArray {
        return this.form.get('parameters') as FormArray;
    }

    parametersArrayAt(index: number): FormGroup {
        return this.parametersArray.at(index) as FormGroup;
    }

    addParameter(data: Parameter) {
        const subForm: FormGroup = this.formBuilder.group({
            id: ['', [Validators.required]],
            name: [''],
            description: [''],
            value: ['', [Validators.required]],
            default_value: ['']
        });
        if (data) {
            subForm.patchValue(data);
        }
        this.parametersArray.push(subForm);
    }

    ngOnDestroy() {
        if (this._subscription != null) {
            this._subscription.unsubscribe();
        }
    }

    save() {

    }

    cancel() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur d\'annuler les modifications ?',
            accept: () => {
                this.ngOnInit();
            }
        });
    }
}