import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TypeService } from '@services/type.service';
import { Type } from '@shared/models/type';
import { LoadTypes } from './types.actions';
import { map } from 'rxjs/operators';

export interface TypesStateModel {
    types: Type[];
}

@State<TypesStateModel>({
    name: 'types',
    defaults: {
        types: []
    }
})
@Injectable()
export class TypesState {
    constructor(private typeService: TypeService) {
    }

    @Selector()
    static getTypes(state: TypesStateModel) {
        return state.types;
    }

    @Action(LoadTypes)
    load(ctx: StateContext<TypesStateModel>) {
        return this.typeService.findAll().pipe(map(types => {
            const list: Type[] = types;
            list.forEach(t => t.value = t.id);
            console.log('Fin chargement des types');
            ctx.setState({
                types: list
            });
        }));
    }

    @Selector()
    static typesByGroup(state: TypesStateModel) {
        return (grp: string) => {
            return state.types.filter(x => x.grp === grp);
        };
    }

}