import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Parameter } from '@shared/models/parameter';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const RESOURCE_PATH = '/parameters';

@Injectable()
export class ParameterService {
    parameters$: Observable<Parameter[]>;

    constructor(private apiService: ApiService) {
    }

    findAll() {
        return this.apiService.get(RESOURCE_PATH);
    }

    saveOrUpdate(parameters: any) {
        if (parameters.id) {
            return this.apiService
                .put(RESOURCE_PATH + '/' + parameters.id, parameters).pipe(
                    tap(() => {
                        //this.refresh.next();
                    })
                );
        } else {
            return this.apiService
                .post(RESOURCE_PATH, parameters);
        }
    }

}
