import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class ButtonService {

    private buttonsSource = new Subject<MenuItem[]>();
    private mainSource = new Subject<MenuItem>();

    buttonsHandler = this.buttonsSource.asObservable();
    mainHandler = this.mainSource.asObservable();

    setItems(mainSource: MenuItem, buttonsSource: MenuItem[]) {
        this.mainSource.next(mainSource);
        this.buttonsSource.next(buttonsSource);
    }

    reset() {
        this.mainSource.next(null);
        this.buttonsSource.next(null);
    }
}
