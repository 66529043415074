import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import ValidatorsUtils from 'app/core/utils/validators-utils';
import { Nutrition } from '@shared/models/nutrition';

@Injectable()
export class NutriFormService {

    constructor(private formBuilder: FormBuilder) {
    }

    getNutriFormGroup(data: Nutrition) {
        const numberPattern = ValidatorsUtils.numberPattern();

        const result = this.formBuilder.group({
            id: [''],
            label: ['', [Validators.required]],
            // Allergene
            idGluten: [''],
            idLupin: [''],
            idCelery: [''],
            idCrustaceans: [''],
            idMilk: [''],
            idSulphureDioxide: [''],
            idSesame: [''],
            idMolluscs: [''],
            idMustard: [''],
            idTreeNuts: [''],
            idEgg: [''],
            idFish: [''],
            idSoybeans: [''],
            idPeanuts: [''],
            // Info Nutri
            valueEnergieKJ: ['', [Validators.pattern(numberPattern)]],
            valueEnergiekCal: ['', [Validators.pattern(numberPattern)]],
            valueMatGrasse: ['', [Validators.pattern(numberPattern)]],
            valueAcideGras: ['', [Validators.pattern(numberPattern)]],
            valueGlucide: ['', [Validators.pattern(numberPattern)]],
            valueSucre: ['', [Validators.pattern(numberPattern)]],
            valueProteine: ['', [Validators.pattern(numberPattern)]],
            valueSel: ['', [Validators.pattern(numberPattern)]],
            // Ingredient
            ingredient: [''],
        });

        if (data) {
            this.patchForm(data, result);
        }
        return result;
    }

    patchForm(data: Nutrition, form: FormGroup) {
        form.patchValue({
            id: data.id,
            label: data.label.label,
            // Allergene
            idGluten: new FormControl(data.id_gluten),
            idLupin: new FormControl(data.id_lupin),
            idCelery: new FormControl(data.id_celery),
            idCrustaceans: new FormControl(data.id_crustaceans),
            idMilk: new FormControl(data.id_milk),
            idSulphureDioxide: new FormControl(data.id_sulphure_dioxide),
            idSesame: new FormControl(data.id_sesame),
            idMolluscs: new FormControl(data.id_molluscs),
            idMustard: new FormControl(data.id_mustard),
            idTreeNuts: new FormControl(data.id_tree_nuts),
            idEgg: new FormControl(data.id_egg),
            idFish: new FormControl(data.id_fish),
            idSoybeans: new FormControl(data.id_soybeans),
            idPeanuts: new FormControl(data.id_peanuts),
            // Info Nutri
            valueEnergieKJ: data.value_energie_k_j,
            valueEnergiekCal: data.value_energie_k_cal,
            valueMatGrasse: data.value_mat_grasse,
            valueAcideGras: data.value_acide_gras,
            valueGlucide: data.value_glucide,
            valueSucre: data.value_sucre,
            valueProteine: data.value_proteine,
            valueSel: data.value_sel,
            // Ingredient
            ingredient: data.ingredient,
        });
    }

}