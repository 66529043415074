import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {ProviderIndexComponent} from './provider-index/provider-index.component';
import { MenuLayoutComponent } from '../layouts/menu/menu-layout.component';
import { AuthGuard } from 'app/core/guard/auth.guard';
import { ProviderViewComponent } from './provider-view/provider-view.component';

const providerRoutes: Routes = [
  {
    path: 'provider',
    component: MenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProviderIndexComponent,
      },
      {
        path: ':id',
        component: ProviderViewComponent,
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(providerRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProviderRoutingModule {
}
