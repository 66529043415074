import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Type } from '@shared/models/type';
import { ApiService } from '@services/api.service';

const RESOURCE_PATH = '/types';

@Injectable()
export class TypeService implements OnInit, OnDestroy {

    private packagingList: Type[] = [];

    private subscriptionPackaging: Subscription;
    private subscriptionAllergen: Subscription;

    constructor(private apiService: ApiService) {

    }

    ngOnInit() {
        this.subscriptionPackaging = this.getAllWithGrp('conditionnement')
            .subscribe(
                data => {
                    this.packagingList = data;
                });

    }

    ngOnDestroy(): void {
        if (this.subscriptionPackaging != null) {
            this.subscriptionPackaging.unsubscribe();
        }
        if (this.subscriptionAllergen != null) {
            this.subscriptionAllergen.unsubscribe();
        }
    }

    public findAll() {
        return this.apiService
            .get(RESOURCE_PATH);
    }

    get getPackagingList() {
        return this.packagingList;
    }


    private getAllWithGrp(grp) {
        return this.apiService
            .get(RESOURCE_PATH + '?grp=' + grp);
    }

}
