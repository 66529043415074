import { AppComponent } from 'app/app.component';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './left-menu.component.html'
  })
export class LeftMenuComponent implements OnInit {
    model: any[];

    constructor(public app: AppComponent) { }

    ngOnInit() {
        this.model = [
            { label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/'] },
            { label: 'Fournisseurs', icon: 'pi pi-inbox', routerLink: ['/provider'] },
            { label: 'Produits', icon: 'pi pi-apple', routerLink: ['/product'] },
            { label: 'Recettes', icon: 'pi pi-list', routerLink: ['/recipe'] },
            { label: 'Productions', icon: 'pi pi-clone', routerLink: ['/production'] },
        ];
    }
}