import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {ProductIndexComponent} from './product-index/product-index.component';
import { MenuLayoutComponent } from '../layouts/menu/menu-layout.component';
import { AuthGuard } from 'app/core/guard/auth.guard';
import { ProductViewComponent } from './product-view/product-view.component';

const productRoutes: Routes = [
  {
    path: 'product',
    component: MenuLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductIndexComponent,
      },
      {
        path: ':id',
        component: ProductViewComponent,
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(productRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductRoutingModule {
}
