import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ApiService } from './api.service';

const RESOURCE_PATH = '/production';
const RESOURCE_PATH_SHEET = '/sheets';

@Injectable()
export class ProductionService {

    constructor(private apiService: ApiService,
        private http: HttpClient) {
    }

    get(id: string | number) {
        return this.apiService
            .get(RESOURCE_PATH + '/' + id);
    }

    getAll(limit: number = null, orderBy: string = null) {
        var params = "";
        var sep = "?";
        if (limit) {
            params += sep + "limit=" + limit;
            sep = "&";
        }
        if (orderBy) {
            params += sep + "orderBy=" + orderBy;
            sep = "&";
        }

        return this.apiService
            .get(RESOURCE_PATH + (params ? params : ''));
    }

    save(production: any, sheetId: string) {
        return this.apiService
            .post(RESOURCE_PATH_SHEET + "/" + sheetId + RESOURCE_PATH, production);
    }

    getPdf(productionId: string) {
        return this.http.get(
            `${environment.api_url}${RESOURCE_PATH}/${productionId}/pdf`,
            { responseType: 'blob' as 'json' }
        );
    }

    /********************************************************
     *                  PICTURE MANAGEMENT
     ********************************************************/

    getPicturesList(productionId: number, productId: any, sheetId: any) {
        if (productId) {
            return this.apiService.get(RESOURCE_PATH + "/" + productionId + "/product/" + productId)
        } else {
            return this.apiService.get(RESOURCE_PATH + "/" + productionId + "/sheet/" + sheetId)
        }
    }

    getPicture(productionId: number, pictureId: any) {
        return this.http.get(
            `${environment.api_url}${RESOURCE_PATH}/${productionId}/picture/${pictureId}`,
            { responseType: 'blob' as 'json' }
        );
    }

    savePicture(productionId: number, productId: string, sheetId: string, file: File) {
        const formData = new FormData();
        formData.append('picture', file);

        const params = new HttpParams();
        params.append('Content-Type', 'multipart/form-data');
        params.append('Accept', 'application/json');

        const options = {
            params,
            reportProgress: false,
        };

        if (productId) {
            return this.http.post(
                `${environment.api_url}/production/${productionId}/product/${productId}/upload`, formData, options);
        } else {
            return this.http.post(
                `${environment.api_url}/production/${productionId}/sheet/${sheetId}/upload`, formData, options);
        }

    }

    deletePicture(productionId: number, pictureId: any) {
        return this.apiService.delete(RESOURCE_PATH + '/' + productionId + "picture/" + pictureId);
    }
}