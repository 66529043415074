import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MyToasterService } from '@services/my-toaster.service';
import { ProductProviderService } from '@services/product.provider.service';
import { ProductService } from '@services/product.service';
import { ProviderService } from '@services/provider.service';
import { TypesStoreService } from '@services/store/types.store.service';
import { Contact } from '@shared/models/contact';
import { ProductProvider } from '@shared/models/product.provider';
import { Provider } from '@shared/models/provider';
import { Type } from '@shared/models/type';
import { TypesGroupName } from 'app/core/enum/types.group.name';
import ValidatorsUtils from 'app/core/utils/validators-utils';
import { RecipeFormService } from 'app/modules/recipe/recipe-form.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-provider-view',
    templateUrl: './provider-view.component.html',
    providers: [MyToasterService, ConfirmationService, RecipeFormService],
    styleUrls: ['./provider-view.component.scss'],
    styles: [`
    ::ng-deep .p-dialog-resizable .p-dialog-content {
      overflow: unset;
    }
  `]
})
export class ProviderViewComponent implements OnInit {
    cols: any[];

    providerId: any;
    providerForm: FormGroup;
    loading: boolean = false;
    submitted: Boolean = false;
    disabled: boolean = false;

    private _subscription: Subscription;

    constructor(private formBuilder: FormBuilder,
        private breadcrumbService: BreadcrumbService,
        private buttonService: ButtonService,
        private providerService: ProviderService,
        private productService: ProductService,
        private productProviderService: ProductProviderService,
        private typesStoreService: TypesStoreService,
        private route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        private myToasterService: MyToasterService,
        private router: Router,
    ) {

        this.breadcrumbService.setItems([
            { label: 'Fournisseurs', routerLink: ['/provider'] },
            { label: 'Détail' }
        ]);

        const menu: MenuItem[] = [
            { label: 'Cancel', icon: 'pi pi-refresh', command: () => { this.cancel(); } },
            { label: 'Delete', icon: 'pi pi-trash', command: () => { this.remove(); } },
            //{ label: 'Log form', icon: 'pi pi-print', command: () => { console.log(this.recipeForm); } },
        ];
        const mainMenu: MenuItem = { label: 'Save', icon: 'pi pi-refresh', command: () => { this.save(); } };
        this.buttonService.setItems(mainMenu, menu);

        this.typesStoreService.getWithGrp(TypesGroupName.conditionnement).subscribe(data => this.conditionnements = data);

        this.providerForm = this.initForm();
    }

    ngOnInit() {
        this.cols = [
            { field: 'product', header: 'Produit' },
            { field: 'packaging', header: 'Conditionnement' },
            { field: 'price', header: 'Prix' },
            { field: 'default', header: 'Défaut' },
        ];

        this.loading = true;

        this.productService.getAll().subscribe(data => {
            this.products = [];
            data.forEach(elem => {
                this.products.push({ value: elem.id, label: elem.name });
            });
        });

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.providerId = id;
            this.providerService.get(id).subscribe(data => {
                this.providerForm = this.initForm(data);
                this.loading = false;
            });

            this.initProductList();
        }
    }

    initForm(provider: Provider = null) {
        const form: FormGroup = this.formBuilder.group({
            id: [''],
            name: ['', [Validators.required]],
            street: [''],
            complement: [''],
            postcode: ['', [Validators.pattern('[0-9]{5}')]],
            city: [''],

            delivery_delay: [''],
            payment: [''],
            min_order: [''],
            delivery_fees: [''],
            franco_port: [''],

            contacts: this.formBuilder.array([]),
        });

        if (provider) {
            if (provider.contacts) {
                const contacts: FormArray = <FormArray>form.get('contacts');
                for (let line = 0; line < provider.contacts.length; line++) {
                    contacts.push(this.initContact(provider.contacts[line]));
                }
            }

            form.patchValue(provider);
        }

        return form;
    }

    /******************************
     * contacts management
     ******************************/

    get formContacts() {
        return <FormArray>this.providerForm.get('contacts');
    }

    formContactsAt(index: number): FormGroup {
        return this.formContacts.at(index) as FormGroup;
    }

    addContact(contact: Contact) {
        this.formContacts.push(this.initContact(contact));
    }

    removeContact(i: number) {
        this.formContacts.removeAt(i);
    }

    initContact(contact: Contact): FormGroup {
        const form = this.formBuilder.group({
            id: [''],
            name: ['', [Validators.required]],
            phone: ['', [Validators.pattern(ValidatorsUtils.phoneNumberPattern())]],
            email: ['', [Validators.email]],
        });
        if (contact != null) {
            form.patchValue(contact);
        }
        return form;
    }

    /******************************
     * products management
     ******************************/

    productProviders: ProductProvider[] = [];
    conditionnements: Array<Type>;

    productForm: FormGroup = this.initProductForm();
    submittedProduct: boolean = false;
    displayDialog: boolean = false;
    products: Array<any>;

    initProductList() {
        this.productProviderService.getAll(this.providerId).subscribe(data => {
            this.productProviders = data;
        });
    }

    addProduct() {
        this.productForm = this.initProductForm();
        this.displayDialog = true;
    }

    editProduct(providerProductId: number) {
        const productProvider = this.productProviders.find(x => x.id == providerProductId);
        if (productProvider) {
            this.productForm = this.initProductForm(productProvider);
            this.displayDialog = true;
        } else {
            this.myToasterService.error();
        }
    }

    initProductForm(productProvider: ProductProvider = null): FormGroup {
        const form = this.formBuilder.group({
            id: [''],
            idProduct: ['', [Validators.required]],
            idProvider: [new FormControl(this.providerId), [Validators.required]],
            idContent: ['', [Validators.required]],
            designation: [''],
            price: ['', [Validators.required, Validators.pattern(ValidatorsUtils.numberPattern())]],
            byDefault: [false],
            active: [true],
        });

        if (productProvider) {
            form.patchValue({
                id: productProvider.id,
                idProduct: new FormControl(productProvider.product.id),
                idContent: new FormControl(productProvider.price.packaging_type_id),
                active: productProvider.active,
                designation: productProvider.designation,
                byDefault: productProvider.by_default,
                price: productProvider.price.net_price
            });
        }
        return form;
    }

    saveProduct() {
        this.submittedProduct = true;
        if (this.productForm.valid) {
            // TODO
        }
    }

    removeProduct(providerProductId: number) {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer ce produit ?',
            accept: () => {
                this.productProviderService.remove(providerProductId).subscribe(() => {
                    this.myToasterService.success('Produit détaché de ce fournisseur avec succès.');
                    this.initProductList();
                });
            }
        });
    }

    /******************************
     *      General
     ******************************/

    save() {
        this.disabled = true;
        this.submitted = true;
        if (this.providerForm.valid) {
            const id = this.providerForm.controls['id'].value;

            // Save data
            this._subscription = this.providerService.saveOrUpdate(this.providerForm.value)
                .subscribe(
                    data => {
                        this.myToasterService.success('Fournisseur ' + (id ? 'mis à jour' : 'ajouté'));
                        this.disabled = false;
                        this.submitted = false;
                        this.ngOnInit();
                    },
                    error => {
                        this.myToasterService.error();
                        this.disabled = false;
                    }
                );
        } else {
            this.disabled = false;
        }
    }

    remove() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer ce fournisseur ?',
            accept: () => {
                const id = this.providerForm.controls['id'].value;
                if (id) {
                    this.providerService.remove(id).subscribe(
                        () => {
                            this.myToasterService.success("Fournisseur supprimé avec succès");
                            this.router.navigate(['/provider']);
                        }
                    );
                }
            }
        });
    }

    cancel() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur d\'annuler les modifications ?',
            accept: () => {
                this.ngOnInit();
            }
        });
    }

}