import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MyToasterService } from '@services/my-toaster.service';
import { ProductionService } from '@services/production.service';
import { RecipeItem } from '@shared/models/recipeItem';
import { ConfirmationService } from 'primeng/api';

export class ImageFile {
    public id: number;
    public src: any;

    constructor(id: number) {
        this.id = id;
    }
}

@Component({
    selector: 'app-production-view-line',
    templateUrl: './production-view-line.component.html',
    providers: [MyToasterService, ConfirmationService]
})
export class ProductionViewLineComponent implements OnInit, OnDestroy {
    @Input() item: RecipeItem;
    @Input() productionId: number;

    images: ImageFile[];
    nbPicture: number = null;
    fileUpload: boolean = false;

    displayCustom: boolean;
    activeIndex: number = 0;

    constructor(private productionService: ProductionService,
        private myToasterService: MyToasterService,
        private confirmationService: ConfirmationService,
    ) {
    }

    ngOnInit() {
        // Retrouver toutes les photos déjà uploadées
        const productId = this.item.id.endsWith('PRODUCT') ? this.item.id.split('-')[0] : null;
        const recipeId = this.item.id.endsWith('SHEET') ? this.item.id.split('-')[0] : null;

        this.productionService.getPicturesList(this.productionId, productId, recipeId).subscribe(datas => {
            this.images = [];
            this.nbPicture = datas.length;
            datas.forEach(pictureId => {
                this.loadPicture(this.productionId, pictureId);
            });
        })
    }

    loadPicture(productionId: number, pictureId: number) {
        const imageFile = new ImageFile(pictureId);
        this.images.push(imageFile);

        this.productionService.getPicture(productionId, imageFile.id).subscribe(
            (stream: Blob) => {
                let reader = new FileReader();
                reader.addEventListener("load", () => {
                    imageFile.src = reader.result;
                }, false);

                if (stream) {
                    reader.readAsDataURL(stream);
                }
            }, error => {
                console.log(error);
            });
    }

    // src : https://medium.com/@amcdnl/file-uploads-with-angular-reactive-forms-960fd0b34cb5
    onFileChange(event: any) {
        this.fileUpload = true;
        const file: File = event.target.files[0];

        const productId = this.item.id.endsWith('PRODUCT') ? this.item.id.split('-')[0] : null;
        const sheetId = this.item.id.endsWith('SHEET') ? this.item.id.split('-')[0] : null;

        this.productionService.savePicture(this.productionId, productId, sheetId, file).subscribe(
            (pictureId: number) => {
                this.loadPicture(this.productionId, pictureId);
                this.myToasterService.success('Image enregistrée avec succès.');
                this.fileUpload = false;
            }, error => {
                this.myToasterService.error();
                this.fileUpload = false;
            }
        );
    }

    imageClick(index: number) {
        this.activeIndex = index;
        this.displayCustom = true;
    }

    removePicture(index: number) {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer cette image ?',
            accept: () => {
                const fileToDelete = this.images[index];
                this.productionService.deletePicture(this.productionId, fileToDelete.id).subscribe(() => {
                    this.myToasterService.success('Image supprimée avec succès.');
                    this.ngOnInit();
                });
            }
        });
    }

    ngOnDestroy() {

    }

    printForm() {
        console.log(this.item);
    }
}