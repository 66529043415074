import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import LocalStorage from '../utils/local-storage';
import { User } from '@shared/models/user';
import { JwtHelperService } from '@auth0/angular-jwt';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const RESOURCE_PATH = '/user';

@Injectable()
export class UserService {


  constructor(private apiService: ApiService,
    public jwtHelper: JwtHelperService) {
  }

  checkEmail(email: string) {
    return this.apiService
      .get(RESOURCE_PATH + '/check_email?email=' + email);
  }

  authenticate(user: User) {
    return this.apiService.post('/login_check', user);
  }

  register(user: User) {
    return this.apiService
      .post(RESOURCE_PATH + '/register', user);
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('current_user');
    localStorage.removeItem('current_project');
  }

  loggedIn() {
    return !this.jwtHelper.isTokenExpired();
  }

  getCurrentUser(): User {
    return LocalStorage.getObjectFromLocalStorage('current_user');
  }

  setCurrentUser(user: User) {
    LocalStorage.setObjectInLocalStorage('current_user', user);
  }

  get() {
    return this.apiService
      .get(RESOURCE_PATH);
  }
}