export class Parameter {
    id: number;
    name: string;
    description: string;
    value: string;
    default_value: string;
    is_writable: boolean;

    static usableValue(parameter: Parameter): string {
      return parameter.value ? parameter.value : parameter.default_value;
    }
  }
  