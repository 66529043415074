import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class MyToasterService {
    constructor(private messageService: MessageService) {
    }

    success(detail: string) {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: detail });
    }

    error(detail: string = 'Une erreur s\'est produite') {
        this.messageService.add({ severity: 'error', summary: 'Erreur !', detail: detail });
    }

    warn(detail: string) {
        this.messageService.add({ severity: 'warn', summary: 'Attention !', detail: detail });
    }

    info(detail: string) {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: detail });
    }
}