import {Component} from '@angular/core';
import { AppComponent } from 'app/app.component';

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html'
})
export class MenuLayoutComponent {
  constructor(public app: AppComponent) { }

}
