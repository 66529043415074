import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { HomeRoutingModule } from './home-routing.module';
import { HomepageComponent } from './homepage/homepage.component';

@NgModule({
  imports: [
		CheckboxModule,
		
		RadioButtonModule,
    
    CommonModule,
    HomeRoutingModule,
    MultiSelectModule,
    DropdownModule,
    MenubarModule,
    
    BrowserModule,
    BrowserAnimationsModule,
    MessageModule,
    MessagesModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    PanelModule,
    ScrollPanelModule,

    SharedModule,
  ],
  declarations: [
    HomepageComponent,
  ]
})
export class HomeModule {
}
