import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CategoryName } from 'app/core/enum/category.name';
import { Category } from '@shared/models/category';
import { Observable } from 'rxjs';
import { CategoriesState } from '@shared/store/categories/categories.state';
import { AddCategory } from '@shared/store/categories/categories.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class CategoryStoreService {
    constructor(private store: Store) {}

    public getWithType(categoryType: CategoryName): Observable<Category[]> {
        return this.store
            .select(CategoriesState.categoryByType)
            .pipe(map(filterFn => filterFn(CategoryName[categoryType])));
    }

    public addNew(category: Category) {
        return this.store.dispatch(new AddCategory(category));
    }
}