import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { Item } from '../item';
import { ProductService } from '@services/product.service';
import { Subscription, Observable } from 'rxjs';
import { ProductProvider } from '@shared/models/product.provider';
import { RecipeFormService } from '../recipe-form.service';
import { RecipeService } from '@services/recipe.service';
import { Recipe } from '@shared/models/recipe';
import FormatUtils from 'app/core/utils/format-utils';
import { Product } from '@shared/models/product';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: '[app-recipe-view-line]',
  templateUrl: './recipe-view-line.component.html'
})
export class RecipeViewLineComponent implements OnInit, OnDestroy {
  @Input() recipeForm: FormGroup;
  sheetFormChanges$: Observable<any>;

  @Input() id: number;
  @Input() submitted: Boolean;

  selectedProduct: Product;
  selectedRecipe: Recipe;
  providers: any[];
  nutriTypes: any[];

  @Output() parentCalculate: EventEmitter<any> = new EventEmitter<any>();

  lastIdItem: number;
  lastIdProvider: number;

  unitPrice: number;
  price: number;

  private _subscriptionRepository: Subscription;

  constructor(private recipeFormService: RecipeFormService,
    private recipeService: RecipeService,
    private productService: ProductService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.calculateData();

    // initialize stream on units
    this.sheetFormChanges$ = this.recipeForm.valueChanges;
    // subscribe to the stream so listen to changes on units
    this.sheetFormChanges$.subscribe(elem => this.calculateData());
  }

  get items(): Array<any> {
    const itemsProduct: any[] = [];
    const itemsRecipe: any[] = [];
    this.recipeFormService.items.forEach(elem => {
      if (elem.type === 'Produit') {
        itemsProduct.push({ value: elem.id, label: elem.name });
      } else {
        itemsRecipe.push({ value: elem.id, label: elem.name });
      }
    });

    return [
      {
        label: 'Produits',
        items: itemsProduct
      },
      {
        label: 'Recette',
        items: itemsRecipe
      }
    ];
  }

  calculateData() {
    // Update Products
    const itemId = this.recipeForm.controls.idItem.value;
    if (itemId) {
      const id = itemId.split('-')[0];
      const type = itemId.split('-')[1];

      if (this.lastIdItem == null || itemId !== this.lastIdItem) {
        this.reinitPrices();
        if (type === 'PRODUCT') {
          this.loadProduct(id);
        } else {
          this.loadRecipe(id);
        }
        this.lastIdItem = itemId;
      }

      // Update Provider
      const idProvider = this.recipeForm.controls.idProvider.value;
      if (this.lastIdProvider == null || idProvider !== this.lastIdProvider) {
        this.updateUnitPrice();
        this.lastIdProvider = idProvider;
      }

    } else {
      this.selectedProduct = null;
      this.selectedRecipe = null;
      this.recipeForm.get('idProvider').clearValidators();
      this.recipeForm.get('idProvider').disable({ onlySelf: true });
    }

    this.calculateTotalPrice();
  }

  loadProduct(id: number) {
    this.recipeForm.get('idProvider').setValidators(Validators.required);
    this.recipeForm.get('idProvider').enable({ onlySelf: true });


    this.productService.get(id).subscribe(data => {
      this.selectedProduct = data;
      this.selectedRecipe = null;

      if (this.recipeForm.dirty) {
        this.recipeForm.patchValue({
          idProvider: ''
        }, { emitEvent: false });
      }

      if (data.product_providers) {
        this.providers = [];
        data.product_providers.forEach(x => {
          this.providers.push({ value: x.id, label: x.provider.name });

          if (x.by_default && this.recipeForm.dirty) {
            this.recipeForm.patchValue({
              idProvider: x.id
            }, { emitEvent: false });
            this.lastIdProvider = x.id;
          }
        });

        this.updateUnitPrice();
      }
    });
  }

  loadRecipe(id: number) {
    this.recipeForm.get('idProvider').clearValidators();
    this.recipeForm.get('idProvider').disable({ onlySelf: true });

    this.recipeService.get(id).subscribe(data => {
      this.selectedRecipe = data;
      this.selectedProduct = null;

      this.providers = [];
      this.recipeForm.patchValue({
        idProvider: ''
      });
      this.updateUnitPrice();
    });
  }

  loadNutriType() {
    this.nutriTypes = [];

    if (this.selectedProduct) {
      if (this.selectedProduct.nutri) {
        this.selectedProduct.nutri.forEach(x => {
          this.nutriTypes.push({ value: x.label.id, label: x.label.label });
        });
      }

      // Récupérer pour le provider selectionné (sans doublons)
      const idProvider = this.recipeForm.controls.idProvider.value;
      const pp = this.selectedProduct.product_providers.find(x => x.id === idProvider);

      if (pp && pp.nutri) {
        pp.nutri.forEach(x => {
          if (!this.nutriTypes.find(e => e.value === x.label.id)) {
            this.nutriTypes.push({ value: x.label.id, label: x.label.label });
          }
        });
      }
    } else if (this.selectedRecipe) {
      if (this.selectedRecipe.nutri) {
        this.selectedRecipe.nutri.forEach(x => {
          this.nutriTypes.push({ value: x.label.id, label: x.label.label });
        });
      }
    }

    if (this.nutriTypes.length == 1) {
      this.recipeForm.patchValue({
        nutriTypeId: this.nutriTypes[0].value
      });
    }
  }

  updateUnitPrice() {
    if (this.selectedProduct) {
      const idProvider = this.recipeForm.controls.idProvider.value;
      if (idProvider) {
        const pp: ProductProvider = this.selectedProduct.product_providers.find(x => x.id === idProvider);
        this.unitPrice = pp.price.net_price;
      }
    } else if (this.selectedRecipe) {
      this.unitPrice = this.selectedRecipe.prices.net_price
    } else {
      this.unitPrice = 0;
    }
    this.loadNutriType();
    this.calculateTotalPrice();
  }

  reinitPrices() {
    this.recipeForm.patchValue({
      price: 0
    }, { onlySelf: true, emitEvent: false });
    this.unitPrice = 0;
  }

  /**
   * Calculate unitPrice * grossQty
   */
  calculateTotalPrice() {
    this.recipeForm.patchValue({
      price: this.recipeForm.controls.grossQty.value != null ?
        FormatUtils.formatNumber(this.unitPrice * this.recipeForm.controls.grossQty.value) :
        0
    }, { onlySelf: true, emitEvent: false });

    this.parentCalculate.emit();
  }

  removeProduct() {
    this.confirmationService.confirm({
      message: 'Êtes-vous sur de supprimer cet ingrédient ?',
      accept: () => {
        /*this.recipeForm.patchValue({
          grossQty: 0,
          netQty: 0,
          price: 0
        });*/

        this.recipeFormService.removeItem(this.id);
        this.parentCalculate.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this._subscriptionRepository != null) {
      this._subscriptionRepository.unsubscribe();
    }
  }
}
