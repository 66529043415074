import {Injectable} from '@angular/core';
import {Provider} from '@shared/models/provider';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApiService} from '@services/api.service';

const RESOURCE_PATH = '/providers';

@Injectable()
export class ProviderService {
  private _refresh = new Subject<void>();

  get refresh() {
    return this._refresh;
  }

  constructor(private apiService: ApiService) {
  }

  get(id: string | number) {
    return this.apiService.get(RESOURCE_PATH + '/' + id);
  }

  getAll() {
    return this.apiService.get(RESOURCE_PATH);
  }

  saveOrUpdate(provider: Provider) {
    if (provider.id) {
      return this.apiService
        .put(RESOURCE_PATH + '/' + provider.id, provider).pipe(
          tap(() => {
            this.refresh.next();
          })
        );
    } else {
      return this.apiService.post(RESOURCE_PATH, provider);
    }
  }

  remove(id: string) {
    return this.apiService
      .delete(RESOURCE_PATH + '/' + id).pipe(
        tap(() => {
          this.refresh.next();
        })
      );
  }
}
