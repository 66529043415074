import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MyToasterService } from '@services/my-toaster.service';
import { RecipeService } from '@services/recipe.service';
import { TypesStoreService } from '@services/store/types.store.service';
import { Provider } from '@shared/models/provider';
import { Type } from '@shared/models/type';
import { TypesGroupName } from 'app/core/enum/types.group.name';
import FormatUtils from 'app/core/utils/format-utils';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { RecipeFormService } from '../recipe-form.service';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-recipe-view',
    templateUrl: './recipe-view.component.html',
    providers: [MyToasterService, RecipeFormService, ConfirmationService],
    styles: [`
        :host ::ng-deep .ui-multiselected-item-token,
        :host ::ng-deep .ui-multiselected-empty-token {
            padding: 2px 4px;
            margin: 0 0.286em 0 0;
            display: inline-block;
            vertical-align:middle;
            height: 1.857em;
        }

        :host ::ng-deep .ui-multiselected-item-token {
            background: #39a3f4;
            color: #ffffff;
        }

    `]
})
export class RecipeViewComponent implements OnInit {
    conditionnements: Array<Type>;

    recipeForm: FormGroup;

    loading: boolean;
    disabled: boolean;

    totalPrice: number;
    totalCost: number;
    costForOne: number;
    priceHt: number;
    priceAndTva: number;

    submitted: Boolean = false;

    private subscriptionRepository: Subscription;
    recipeFormChanges$: Observable<any>;
    priceFormChanges$: Observable<any>;

    constructor(private breadcrumbService: BreadcrumbService,
        private buttonService: ButtonService,
        private recipeService: RecipeService,
        private recipeFormService: RecipeFormService,
        private typesStoreService: TypesStoreService,
        private route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        private myToasterService: MyToasterService,
        private router: Router,
        private store: Store,
    ) {

        this.breadcrumbService.setItems([
            { label: 'Recettes', routerLink: ['/recipe'] },
            { label: 'Détail' }
        ]);

        const menu: MenuItem[] = [
            { label: 'Cancel', icon: 'pi pi-refresh', command: () => { this.cancel(); } },
            { label: 'Delete', icon: 'pi pi-trash', command: () => { this.remove(); } },
            { label: 'Log form', icon: 'pi pi-print', command: () => { console.log(this.recipeForm); } },
        ];
        const mainMenu: MenuItem = { label: 'Save', icon: 'pi pi-refresh', command: () => { this.save(); } };
        this.buttonService.setItems(mainMenu, menu);

        this.recipeForm = this.recipeFormService.form;

        // initialize stream on units
        this.recipeFormChanges$ = this.recipeForm.valueChanges;
        // subscribe to the stream so listen to changes on units
        this.recipeFormChanges$.subscribe(elem => this.calculateData());
    }

    toggleSubmittedForm() {
        this.submitted = false;
    }

    ngOnInit() {
        this.loading = true;
        this.submitted = false;

        this.typesStoreService.getWithGrp(TypesGroupName.conditionnementFiche).subscribe(data => this.conditionnements = data);

        this.route.params.subscribe(routeParams => {
            this.recipeFormService.initItems(routeParams.id);

            this.subscriptionRepository = this.recipeService.get(routeParams.id)
                .subscribe(
                    data => {
                        this.recipeFormService.initForm(data);

                    }
                );

        });

    }

    ngOnDestroy(): void {
        if (this.subscriptionRepository != null) {
            this.subscriptionRepository.unsubscribe();
        }
    }

    /******************************
     * getter for static lists
     ******************************/

    get pricesForm(): FormGroup {
        return this.recipeFormService.pricesGroup;
    }

    get nutriForm(): FormArray {
        return this.recipeFormService.nutriForm;
    }

    get providersForm(): FormArray {
        return this.recipeFormService.providersArray;
    }

    get providers(): Array<Provider> {
        return this.recipeFormService.providers;
    }

    /******************************
     * provider management
     ******************************/

    addProduct() {
        this.recipeFormService.addProduct(null);
    }

    deleteNutri() {
        this.recipeFormService.deleteNutri();
    }

    calculateNutrition() {
        if (this.nutriForm.length > 0) {
            this.confirmationService.confirm({
                message: 'Êtes-vous sur de relancer le calcul ? Celà effacera les données existantes.',
                accept: () => {
                    this.recipeFormService.calculateNutrition();
                }
            });
        } else {
            this.recipeFormService.calculateNutrition();
        }
    }

    save() {
        this.submitted = true;
        if (this.recipeForm.valid) {
            this.recipeFormService.save();
        }
    }

    cancel() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur d\'annuler les modifications ?',
            accept: () => {
                this.ngOnInit();
            }
        });
    }

    remove() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer cette recette ?',
            accept: () => {
                const id = this.route.snapshot.paramMap.get('id');
                if (id) {
                    this.recipeService.remove(id).subscribe(
                        data => {
                            this.myToasterService.success("Recette supprimée avec succès");
                            this.store.dispatch([new LoadErrors()]);
                            this.router.navigate(['/recipe']);
                        }
                    );
                }
            }
        });
    }

    calculateTotal() {
        this.totalPrice = 0;
        const productProviders = this.providersForm as FormArray;
        for (let line = 0; line < productProviders.length; line++) {
            const subForm = productProviders.at(line) as FormGroup;
            this.totalPrice += subForm.controls.price.value;
        }
        this.calculateData();
    }

    calculateData() {
        this.toggleSubmittedForm();
        const alea = this.recipeForm.get('alea').value;
        this.totalCost = 0;
        if (alea && this.totalPrice) {
            this.totalCost = FormatUtils.formatNumber(this.totalPrice + (alea / 100 * this.totalPrice));
        }

        const qty = this.pricesForm.get('quantity').value;
        this.costForOne = 0;
        if (this.totalCost && qty && qty !== '') {
            this.costForOne = FormatUtils.formatNumber(this.totalCost / qty);
        }

        const coef = this.recipeForm.get('coef').value;
        this.priceHt = 0;
        if (coef && coef !== '' && this.costForOne !== 0) {
            this.priceHt = FormatUtils.formatNumber(this.costForOne * coef);
        }

        const tva = this.recipeForm.get('tva').value;
        this.priceAndTva = 0;
        if (tva && tva !== '' && this.priceHt !== 0) {
            this.priceAndTva = FormatUtils.formatNumber(this.priceHt + (tva / 100 * this.priceHt));
        }

        this.pricesForm.patchValue({
            net_price: this.priceAndTva,
            gross_price: this.priceHt,
        }, { onlySelf: false, emitEvent: false });

        this.loading = false;
    }


}