import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ProjectService } from '@services/project.service';
import { User } from '@shared/models/user';
import { Project } from '@shared/models/project';
import { MyToasterService } from '@services/my-toaster.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  providers: [MyToasterService]
})
export class AuthenticationComponent {
  compute: boolean;
  loginForm: FormGroup;
  error: '';

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private projectService: ProjectService,
    private mMyToasterService: MyToasterService,
    private router: Router) {
    this.compute = false;
    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.compute = true;
    this.userService
      .authenticate(this.loginForm.value)
      .subscribe(
        data => {

          localStorage.setItem('id_token', data['token']);

          this.userService.get().subscribe(
            dataBis => {
              const user: User = dataBis.user;
              const projects: Project[] = dataBis.projects;

              // TODO : essayer de trouver un user avec un ID pour mettre dans le localStorage
              this.userService.setCurrentUser(user);

              this.projectService.setCurrentProject(projects[0]);
              // Forward to dashboard

              this.compute = false;
              this.router.navigate(['/user/loading']);
            }
          );
        },
        error => {
          this.compute = false;
          this.mMyToasterService.error("Erreur d'authentification");
          this.error = error.message;
        }
      );
    this.compute = true;
  }

}
