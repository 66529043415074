import { Component } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ButtonService } from '@services/button.service';
import { MyToasterService } from '@services/my-toaster.service';
import { ProductService } from '@services/product.service';
import { TypesStoreService } from '@services/store/types.store.service';
import { Product } from '@shared/models/product';
import { Type } from '@shared/models/type';
import { TypesGroupName } from 'app/core/enum/types.group.name';
import { Subscription, Observable } from 'rxjs';
import { ProductFormService } from '../product-form.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { LoadErrors } from '@shared/store/errors/errors.actions';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-product-view',
    templateUrl: './product-view.component.html',
    providers: [MyToasterService, ProductFormService, ConfirmationService],
    styles: [`
        :host ::ng-deep .ui-multiselected-item-token,
        :host ::ng-deep .ui-multiselected-empty-token {
            padding: 2px 4px;
            margin: 0 0.286em 0 0;
            display: inline-block;
            vertical-align:middle;
            height: 1.857em;
        }

        :host ::ng-deep .ui-multiselected-item-token {
            background: #39a3f4;
            color: #ffffff;
        }

    `]
})
export class ProductViewComponent {
    productForm: FormGroup;

    providersList: Array<any>;
    conditionnements: Array<Type>;

    loading: boolean;
    elem: Product;
    disabled: boolean;

    submitted: Boolean = false;

    private subscriptionRepository: Subscription;
    productFormChanges$: Observable<any>;

    constructor(private breadcrumbService: BreadcrumbService,
        private buttonService: ButtonService,
        private productService: ProductService,
        private productFormService: ProductFormService,
        private typesStoreService: TypesStoreService,
        private route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        private myToasterService: MyToasterService,
        private router: Router,
        private store: Store,
    ) {

        this.breadcrumbService.setItems([
            { label: 'Produits', routerLink: ['/product'] },
            { label: 'Détail' }
        ]);

        const menu: MenuItem[] = [
            { label: 'Cancel', icon: 'pi pi-refresh', command: () => { this.cancel(); } },
            { label: 'Delete', icon: 'pi pi-trash', command: () => { this.remove(); } },
            { label: 'Log form', icon: 'pi pi-print', command: () => { console.log(this.productForm); } },
        ];
        const mainMenu: MenuItem = { label: 'Save', icon: 'pi pi-refresh', command: () => { this.save(); } };
        this.buttonService.setItems(mainMenu, menu);

        this.productForm = this.productFormService.form;

        // initialize stream on units
        this.productFormChanges$ = this.productForm.valueChanges;
        // subscribe to the stream so listen to changes on units
        this.productFormChanges$.subscribe(elem => this.toggleSubmittedForm());
    }

    toggleSubmittedForm() {
        this.submitted = false;
    }

    ngOnInit() {
        this.submitted = false;
        this.loading = true;

        this.typesStoreService.getWithGrp(TypesGroupName.conditionnement).subscribe(data => this.conditionnements = data);

        this.route.params.subscribe(routeParams => {
            this.subscriptionRepository = this.productService.get(routeParams.id)
                .subscribe(
                    data => {
                        this.elem = data;

                        this.productFormService.initForm(data);
                        this.productForm = this.productFormService.form;

                        this.loading = false;
                    }
                );
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionRepository != null) {
            this.subscriptionRepository.unsubscribe();
        }
    }

    /******************************
     * getter for static lists
     ******************************/

    get providers(): Array<any> {
        if (this.providersList == null) {
            this.providersList = [];
            this.productFormService.providers.forEach(elem => {
                this.providersList.push({ value: elem.id, label: elem.name });
            });
        }
        return this.providersList;
    }

    /******************************
     * provider management
     ******************************/

    get providersFormArray() {
        return this.productFormService.providersArray;
    }

    providersFormArrayAt(index: number): FormGroup {
        return this.productFormService.providersArray.at(index) as FormGroup;
    }

    providersNutriFormArrayAt(index: number): FormArray {
        return this.productFormService.providersArray.at(index).get('nutri') as FormArray;
    }

    addProvider() {
        this.productFormService.addProvider(null);
    }

    removeProvider(id: number) {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer ce fournisseur ?',
            accept: () => {
                this.productFormService.removeProvider(id);
            }
        });
    }

    changeDefault(id: number) {
        for (let line = 0; line < this.providersFormArray.length; line++) {
            const subForm = this.providersFormArray.at(line) as FormGroup;

            subForm.patchValue({
                byDefault: (id === line ? subForm.controls.byDefault.value : false)
            });
        }
    }

    /******************************
     * nutri management
     ******************************/

    get nutriFormArray() {
        return this.productFormService.nutriArray;
    }

    addNutri() {
        this.productFormService.addNutri(null);
    }

    deleteNutri(id: number) {
        this.productFormService.removeNutri(id);
    }

    addProviderNutri(id: number) {
        this.productFormService.addProviderNutri(id, null);
    }

    deleteProviderNutri(idProvider: number, id: number) {
        this.productFormService.removeProviderNutri(idProvider, id);
    }

    save() {
        this.submitted = true;
        if (this.productForm.valid) {
            this.productFormService.save();
        }
    }

    cancel() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur d\'annuler les modifications ?',
            accept: () => {
                this.ngOnInit();
            }
        });
    }

    remove() {
        this.confirmationService.confirm({
            message: 'Êtes-vous sur de supprimer ce produit ?',
            accept: () => {
                const id = this.route.snapshot.paramMap.get('id');
                if (id) {
                    this.productService.remove(id).subscribe(
                        data => {
                            this.myToasterService.success("Produit supprimé avec succès");
                            this.store.dispatch([new LoadErrors()]);
                            this.router.navigate(['/product']);
                        }
                    );
                }
            }
        });
    }

}  